
import { LocalNotifications } from '@capacitor/local-notifications';
import localNotificationsMixin from '../mixins/localNotifications';

const generateId = () => Math.floor(Math.random() * 10);

const createNotification = (opts = {}) => {
  return {
    id: generateId(),
    title: 'Test Local Notification',
    body: 'This is the test body',
    ...opts,
  };
};

const schedule = async (opts = {}) => {
  const notifications = [createNotification(opts)];
  const result = await LocalNotifications.schedule({ notifications });

  // getPendingNotifications();

  // eslint-disable-next-line no-console
  console.log('schedule result:', result);
};

const scheduleOnce = async (opts = {}) => {
  const seconds = opts.seconds || 10;
  const secondsFromNow = new Date(new Date().getTime() + (seconds * 1000));
  const notifications = [
    {
      ...createNotification(opts),
      schedule: { at: secondsFromNow },
    },
  ];

  const result = await LocalNotifications.schedule({ notifications });
  // eslint-disable-next-line no-console
  console.log('schedule result:', result);

  // getPendingNotifications();
};

export default {
  name: 'LocalNotifications',
  mixins: [
    localNotificationsMixin,
  ],
  data() {
    return {
      tests: [
        {
          label: 'Schedule Now',
          callback: schedule,
          opts: {
            body: 'from Schedule Now',
          },
        },
        {
          label: 'Schedule 5s',
          callback: scheduleOnce,
          opts: {
            seconds: 5,
            body: 'from Schedule 5s',
          },
        },
        {
          label: 'Schedule Every 60s',
          callback: schedule,
          opts: {
            body: 'from Schedule Every 60s',
            schedule: {
              every: 'second',
              count: 60,
            },
          },
        },
      ],

      tableColDropdown: {
        isSortAvailable: true,
        isDeleteAvailable: false,
        isInsertLeftAvailable: false,
        isInsertRightAvailable: false,
        isMoveAvailable: false,
      },
      tableRowDropdown: {
        displaySettings: {
          isAvailable: false,
        },
      },
    };
  },
  computed: {
    itemsToRemindTableData() {
      // cherry-pick the table columns we want to show
      const fields = {
        description: {
          label: 'Description',
        },
        frequency: {
          label: 'Freq',
        },
        remindAt: {
          label: 'Remind At',
        },
        remindAtOriginalStr: {
          label: 'First Reminder',
        },
        remindAtNextStr: {
          label: 'Next Reminder',
        },
      };

      // This array-of-arrays is the format expected by active-table
      const tbl = [];
      tbl[0] = Object.values(fields).map(f => f.label);

      if (Array.isArray(this.lnItemsToRemindDetailed)) {
        this.lnItemsToRemindDetailed
          .map(i => ({
            ...i,
            remindAtOriginalStr: i.remindAtOriginal.format('MM-DD HH:mm'),
            remindAtNextStr: i.remindAtNext.format('MM-DD HH:mm'),
          }))
          .forEach((i) => {
            const values = [];

            Object.keys(fields).forEach((f) => {
              values.push(i[f]);
            });

            tbl.push(values);
          });
      }

      return tbl;
    },
    notificationsTableData() {
      // cherry-pick the table columns we want to show
      const fields = {
        time: {
          label: 'Time',
        },
        body: {
          label: 'Body',
        },
      };

      // This array-of-arrays is the format expected by active-table
      const tbl = [];
      tbl[0] = Object.values(fields).map(f => f.label);

      if (Array.isArray(this.lnNotifications)) {
        this.lnNotifications
          .map(i => ({
            ...i,
            time: this.getDayFromDate(i.schedule.at).format('MM-DD HH:mm'),
          }))
          .forEach((i) => {
            const values = [];

            Object.keys(fields).forEach((f) => {
              values.push(i[f]);
            });

            tbl.push(values);
          });
      }

      return tbl;
    },
  },
  methods: {
    handleTest(testIndex) {
      const test = this.tests[testIndex];

      if (test && typeof test.callback === 'function') {
        // eslint-disable-next-line no-console
        console.log(test);
        test.callback(test.opts);
      }
    },
    getDayFromDate(d) {
      return this.$dayjs(d);
    },
  },
};
